<template>
    <v-container style="max-width:100vw;">
        <div style="background:white;" class="px-6 py-4">
            <v-text-field @keydown.enter="searchNow=!searchNow" class="mx-6" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
            <v-data-table 
            height="600"
            fixed-header
            :footer-props="{'items-per-page-options':[15, 30, 50, companiesLength]}"
            v-if="showTable"
            :headers="headers" 
            :items="companies"
            class="elevation-0 px-6 pb-4 pt-10"
            :options.sync="options"
            :server-items-length="totalCompanies"
            :loading="loading">
                <template v-slot:[`item.influencer`]="{ item }">
                    <v-btn v-if="item.influencer!=undefined" class="btnText" :to="{ path: '/influencers/influencer/'+ item.influencer.id}">{{item.influencer.name}} {{item.influencer.last}}</v-btn>
                </template>
                <!-- Creación -->
                <template v-slot:[`item.created_at`]="{ item }">
                    {{item.created_at.slice(0, 10)}}
                </template>
                <!-- Actualización -->
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{item.updated_at.slice(0, 10)}}
                </template>
                <template v-slot:[`item.docs`]="{ item }">
                    <div @click="docsDialog=true, agency=item" style="cursor: pointer;">
                    <v-progress-linear v-model="item.docs" color="primary" dark height="25" style="pointer-events: none;">
                        <template v-slot:default="{ value }">
                            <strong>{{item.docs/20}}/{{5}}</strong>
                        </template>
                    </v-progress-linear>
                    </div>
                </template>
                <template v-slot:[`item.brands`]="{ item }">
                    <v-chip-group>
                        <v-chip x-small color="primary" v-for="brand in item.brands" :key="brand.id">{{brand.name.toUpperCase()}}</v-chip>
                    </v-chip-group>
                </template>
                <!-- Tabla sin información -->
                <template v-slot:no-data>
                    No existen registros de agencias aún
                </template>
            </v-data-table>
        </div>
        <v-dialog v-model="docsDialog" width="650px">
            <v-card class="pa-8" style="text-align:center;">
                <v-btn v-bind:href="'https://unopack.mx/files/' + agency.legal_representative_identification" target="_blank" rounded color="primary" class="elevation-0" :disabled="agency.legal_representative_identification==null">
                    Identificación Oficial (ID) del Representante Legal o Apoderado
                </v-btn>
                <br/>
                <br/>
                <v-btn v-bind:href="'https://unopack.mx/files/' + agency.constitutive_act" target="_blank" rounded color="primary" class="elevation-0" :disabled="agency.constitutive_act==null">
                    Acta Constitutiva
                </v-btn>
                <br/>
                <br/>
                <v-btn v-bind:href="'https://unopack.mx/files/' + agency.power_of_attorney" target="_blank" rounded color="primary" class="elevation-0" :disabled="agency.power_of_attorney==null">
                    Poder Notarial
                </v-btn>
                <br/>
                <br/>
                <v-btn v-bind:href="'https://unopack.mx/files/' + agency.proof_of_tax_situation" target="_blank" rounded color="primary" class="elevation-0" :disabled="agency.proof_of_tax_situation==null">
                    Constancia de situación fiscal / Tax ID. (CIF)
                </v-btn>
                <br/>
                <br/>
                <v-btn v-bind:href="'https://unopack.mx/files/' + agency.proof_of_address" target="_blank" rounded color="primary" class="elevation-0" :disabled="agency.proof_of_address==null">
                    Comprobante de Domicilio
                </v-btn>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    components: {
        
    }, 
    props:{
        
    },
    data: () => ({
        docsDialog:false,
        agency:{},
        searchNow:false,
        search:'',
        options: {},
        showTable:true,
        companies:[],
        totalCompanies:0,
        loading:false,
        companiesLength:0
    }),
    computed: {
        
        currentUser:{
            get(){
                return this.$store.state.currentUser.user;
            }
        },
        headers(){ 
            return [
            { text: 'Agencia', value: 'name'},
            { text: 'Marcas', value: 'brands', sortable: false},
            { text: 'Responsable', value: 'agent', sortable: false},

            { text: 'Documentos', value: 'docs'},

            { text: 'Email', value: 'email'},
            { text: 'Teléfono', value: 'phone'},

            { text: 'Razón Social', value: 'razon_social'},
            { text: 'RFC', value: 'rfc'},
            { text: 'Dirección Fiscal', value: 'fiscal_address'},

            { text: 'Medio de Contacto', value: 'contact_medium'},

            { text: 'Creación', value: 'created_at'},
            { text: 'Actualización', value: 'updated_at'},
        ]},
    },
    watch: {
        searchNow: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
        currentUser: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods: {
        docsPercentage(item){
            var sum = 0
            if(item.legal_representative_identification!=null){
                sum = sum + 20
            }
            if(item.constitutive_act!=null){
                sum = sum + 20
            }
            if(item.power_of_attorney!=null){
                sum = sum + 20
            }
            if(item.proof_of_tax_situation!=null){
                sum = sum + 20
            }
            if(item.proof_of_address!=null){
                sum = sum + 20
            }
            return sum
        },
        getDataFromApi () {
            this.loading = true
            this.apiCall().then(data => {
                this.companies = data.items
                this.totalCompanies = data.total
                this.loading = false
            })
        },
        apiCall () {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                var items = []
                var total = 0
                var link = this.$store.state.currentUser.client_filter
                /*if(localStorage.getItem('filtersAgencies')!=null){
                    link = JSON.parse(localStorage.getItem('filtersAgencies'))+'&'
                }*/
                if(this.search!=''){
                    link = link + 'filter[name]='+this.search+'&'
                }
                if(sortBy.length === 1){
                    if(sortDesc[0]){
                        link = link + "sort=-" + sortBy[0] + '&'
                    }else{
                        link = link + "sort=" + sortBy[0] + '&'
                    }
                }
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/agencies?" + link + "page=" + page + "&itemsPerPage=" + itemsPerPage).then(response => {
                    this.companiesLength = response.data.meta.total
                    items = this.mapAgencies(response.data.data)
                    total = response.data.meta.total
                    resolve({
                        items,
                        total,
                    })
                })
            })
        },
        returnId(item){
            if(item!=undefined){
                return item.id
            }else{
                return ''
            }
        },
        returnName(origin){
            if(origin!=undefined){
                return origin.name
            }
        },
        mapAgencies(agencies){
            return agencies.map(id=>{
                return{
                    id: id.id,
                    name: id.name.toUpperCase(),
                    created_at: id.created_at,
                    updated_at: id.updated_at,
                    brands: id.brands,
                    agent: id.agent.name + ' ' + id.agent.last,
                    email: id.email,
                    fiscal_address: id.fiscal_address,
                    phone: id.phone,
                    razon_social: id.razon_social,
                    rfc: id.rfc,
                    contact_medium: id.contact_medium,
                    legal_representative_identification: id.legal_representative_identification,
                    constitutive_act: id.constitutive_act,
                    power_of_attorney: id.power_of_attorney,
                    proof_of_tax_situation: id.proof_of_tax_situation,
                    proof_of_address: id.proof_of_address,
                    docs:this.docsPercentage(id)
                }
            });
        },
    }
}
</script>

<style>
    @media(min-width: 980px){
        .gato{
            transform: translateX(14px);
        }
    }
    .btnText{
        padding: 0px!important;
        height: auto!important;
        background: white!important;
        box-shadow: none!important;
        /*text-transform: none;*/
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0;
    }
</style>