var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('div',{staticClass:"px-6 py-4",staticStyle:{"background":"white"}},[_c('v-text-field',{staticClass:"mx-6",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchNow=!_vm.searchNow}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.showTable)?_c('v-data-table',{staticClass:"elevation-0 px-6 pb-4 pt-10",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, _vm.companiesLength]},"headers":_vm.headers,"items":_vm.companies,"options":_vm.options,"server-items-length":_vm.totalCompanies,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.influencer",fn:function(ref){
var item = ref.item;
return [(item.influencer!=undefined)?_c('v-btn',{staticClass:"btnText",attrs:{"to":{ path: '/influencers/influencer/'+ item.influencer.id}}},[_vm._v(_vm._s(item.influencer.name)+" "+_vm._s(item.influencer.last))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, 10))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.slice(0, 10))+" ")]}},{key:"item.docs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.docsDialog=true, _vm.agency=item}}},[_c('v-progress-linear',{staticStyle:{"pointer-events":"none"},attrs:{"color":"primary","dark":"","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(item.docs/20)+"/"+_vm._s(5))])]}}],null,true),model:{value:(item.docs),callback:function ($$v) {_vm.$set(item, "docs", $$v)},expression:"item.docs"}})],1)]}},{key:"item.brands",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.brands),function(brand){return _c('v-chip',{key:brand.id,attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(brand.name.toUpperCase()))])}),1)]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de agencias aún ")]},proxy:true}],null,true)}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"650px"},model:{value:(_vm.docsDialog),callback:function ($$v) {_vm.docsDialog=$$v},expression:"docsDialog"}},[_c('v-card',{staticClass:"pa-8",staticStyle:{"text-align":"center"}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"href":'https://unopack.mx/files/' + _vm.agency.legal_representative_identification,"target":"_blank","rounded":"","color":"primary","disabled":_vm.agency.legal_representative_identification==null}},[_vm._v(" Identificación Oficial (ID) del Representante Legal o Apoderado ")]),_c('br'),_c('br'),_c('v-btn',{staticClass:"elevation-0",attrs:{"href":'https://unopack.mx/files/' + _vm.agency.constitutive_act,"target":"_blank","rounded":"","color":"primary","disabled":_vm.agency.constitutive_act==null}},[_vm._v(" Acta Constitutiva ")]),_c('br'),_c('br'),_c('v-btn',{staticClass:"elevation-0",attrs:{"href":'https://unopack.mx/files/' + _vm.agency.power_of_attorney,"target":"_blank","rounded":"","color":"primary","disabled":_vm.agency.power_of_attorney==null}},[_vm._v(" Poder Notarial ")]),_c('br'),_c('br'),_c('v-btn',{staticClass:"elevation-0",attrs:{"href":'https://unopack.mx/files/' + _vm.agency.proof_of_tax_situation,"target":"_blank","rounded":"","color":"primary","disabled":_vm.agency.proof_of_tax_situation==null}},[_vm._v(" Constancia de situación fiscal / Tax ID. (CIF) ")]),_c('br'),_c('br'),_c('v-btn',{staticClass:"elevation-0",attrs:{"href":'https://unopack.mx/files/' + _vm.agency.proof_of_address,"target":"_blank","rounded":"","color":"primary","disabled":_vm.agency.proof_of_address==null}},[_vm._v(" Comprobante de Domicilio ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }